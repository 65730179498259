<template>
  <div class="spinner">
    <span class="svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        viewBox="0 0 24 24"
      >
        <g>
          <circle cx="3" cy="12" r="2" :fill="color" />
          <circle cx="21" cy="12" r="2" :fill="color" />
          <circle cx="12" cy="21" r="2" :fill="color" />
          <circle cx="12" cy="3" r="2" :fill="color" />
          <circle cx="5.64" cy="5.64" r="2" :fill="color" />
          <circle cx="18.36" cy="18.36" r="2" :fill="color" />
          <circle cx="5.64" cy="18.36" r="2" :fill="color" />
          <circle cx="18.36" cy="5.64" r="2" :fill="color" />
          <animateTransform
            attributeName="transform"
            dur="1.5s"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 12;360 12 12"
          />
        </g>
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    size: {
      type: String,
      default: "48", // Default size is 48px
    },
    color: {
      type: String,
      default: "black", // Default color is black
    },
  },
};
</script>

<style scoped>
.spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.svg {
  margin-top: 0rem;
}
</style>
